import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from "react-detect-click-outside";
import ReactList from 'react-list';
import { FiSearch } from 'react-icons/fi';
import { MdTune } from 'react-icons/md';
import { searchActions } from "actions/search";
import { SearchDetails } from 'models/SearchDetails';
import { SelectModel } from 'models/SelectModel';
import SearchResultList from 'components/SearchResultsList/index';
import { IoCloseCircle } from "react-icons/io5";
import './style.scss';

const searchOptions: SelectModel[] = [
  { key: 'network', label: 'Network' },
  // { key: 'current-layer', label: 'Current Layer' }
] 

interface SearchData {
  type: string,
  selectedKey: string,
  keyword: string,
  category: string,
}

interface Props {
  isNodeSelected: () => boolean,
  searchContentData: SearchDetails,
  onSelectSearchDetailsItem: (item: string) => void,
  onSelectSearchDetailsType: (type: string) => void,
}

const SearchContainer: React.FC<Props> = ({
  isNodeSelected,
  searchContentData,
  onSelectSearchDetailsItem,
  onSelectSearchDetailsType,
}) => {
  const [isSearchFocused, setSearchFocused] = useState(false);
  const [isSearchFilterOpened, setSearchFilterOpened] = useState(false);
  const [searchContent, setSearchContent] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchData>();
  const [totalItems, setTotalItems] = useState<number>(0);

  const focusSearchContainer = (e: any) => {
    e.preventDefault();
    setSearchFocused(true);
  };

  const blurSearchContainer = () => {
    setSearchFilterOpened(false);
    setSearchFocused(false);
  };

  const toggleSearchFilter = (e: any) => {
    e.preventDefault();
    setSearchFocused(true);
    setSearchFilterOpened(!isSearchFilterOpened);
  };

  const onChangeSearchContent = (event: any) => {
    setSearchContent(event.target.value);
  }

  const onKeyDownPressed = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSearchButtonClicked();
      }
  }

  const onSearchButtonClicked = () => {
    setSearchData({
      type: searchContentData.type ?? '',
      selectedKey: searchContentData.selectedKey ?? '',
      keyword: searchContent,
      category:  searchContentData.selectedCategory ?? '',
    })
    setSearchFocused(true);
  }

  const getSearchOptionClass = (searchOptionKey: string) => {
    if(searchOptionKey === 'current-layer' && isNodeSelected() === false){
      return "disabled";
    }else if(searchOptionKey === searchContentData.type){
      return "selected";
    }else{
      return "";
    }
  }

  const renderSearchOptions = () => {
    return (
      <ul>
        {searchOptions.map((searchOption) => (
          <li
            className={getSearchOptionClass(searchOption.key)}
            key={searchOption.key}
            onClick={() => onSearchOptionClicked(searchOption.key)}
          >
            <span>
              {searchOption.label}
            </span>
          </li>
        ))}
      </ul>
    )
  }

  const onSearchOptionClicked = (key: string) => {
    setSearchFilterOpened(false);
    onSelectSearchDetailsType(key);
  }

  const refSearchContainer = useDetectClickOutside({ onTriggered: blurSearchContainer });

  return (
    <div className={`xa-search-container${isSearchFocused ? ' focused' : ''}`} ref={refSearchContainer}>
      <FiSearch className="xa-search-icon" onClick={onSearchButtonClicked}/>
      <input type="search" id="search"
        placeholder="Search" value={searchContent} onClick={focusSearchContainer}
        onKeyDown={onKeyDownPressed}
        onChange={onChangeSearchContent} />
        {searchContent.length > 0 && (
        <IoCloseCircle
          className="xa-search-clear"
          onClick={() => setSearchContent('')}
        />
      )}
      {(!isSearchFocused && totalItems > 0) &&
        <span className="xa-search-results-count">{totalItems}</span>
      }
      <span className="xa-search-results-category">{searchContentData.selectedCategory === 'Accidents' ? 'Accidents: ' : ''} {searchContentData.selectedLabel}</span>
      <MdTune className="xa-search-filter-toggle" onClick={toggleSearchFilter} />
      <div className={`xa-search-filter${isSearchFilterOpened ? ' opened' : ''}`}>
        <div className="xa-search-filter-title">
          Search on:
        </div>
        {renderSearchOptions()}
      </div>
      {
        isSearchFocused &&
        <SearchResultList
          keyword={searchData?.keyword}
          type={searchData?.type}
          selectedKey={searchData?.selectedKey}
          category={searchData?.category}
          setSearchContent={setSearchContent}
          setSearchFocused={setSearchFocused}
          onSelectSearchDetailsItem={onSelectSearchDetailsItem}
          setTotalItems={setTotalItems}
        />
      }
    </div>
  );
};

export default SearchContainer;
