import { useEffect, useRef, useInsertionEffect } from "react";
import { initializeMap } from "../../esri/map";
import { useUserActions } from "actions/user";
import './style.scss';

type Props = {
  setView: React.Dispatch<React.SetStateAction<any>>;
  setBaseLayer: React.Dispatch<React.SetStateAction<any>>;
};

export const MapView: React.FC<Props> = ({
  setView, setBaseLayer,
}) => {
  const mapRef = useRef() as any;
  const userActions = useUserActions();


  useInsertionEffect(() => {
    const theme = userActions.getUserTheme();
    require(`@arcgis/core/assets/esri/themes/${theme}/main.css`);
  }, []);

  useEffect(() => {
    const view = initializeMap(mapRef.current, setBaseLayer);
    setView(view);
  }, [setView]); // only after first render

  return <div id="map" ref={mapRef}></div>;
};
