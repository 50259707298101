import React from 'react';
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <div className="main-content">
      <Outlet />
    </div>
  );
}

export default MainLayout;
