import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  username?: string,
  token?: string,
  admin: boolean,
  created?: Date,
  email?: string,
  firstname?: string,
  inactive: boolean,
  lastActive?: Date,
  surname?: string,
  clustering?: boolean
}

const initialState: UserState = {
  username: undefined,
  token: undefined,
  admin: false,
  inactive: false,
  clustering: true
}

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.admin = action.payload.admin;
      state.created = action.payload.created;
      state.email = action.payload.email;
      state.firstname = action.payload.firstname;
      state.inactive = action.payload.inactive;
      state.lastActive = action.payload.lastActive;
      state.surname = action.payload.surname;
      state.clustering = action.payload.clustering
    },
    removeUser: (state) => {
      state = {
        ...initialState,
      };
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = counterSlice.actions

export default counterSlice.reducer
