import React, { useState, useEffect } from 'react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { Categories, useLegendActions } from 'actions/legend';
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import Symbology from './symbology';
import VisualisationRule from './visualisationRules';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import {
  MdClose,
  MdColorLens, MdFullscreenExit, MdOutlineRule
} from 'react-icons/md';
import { RgbaColor } from "react-colorful";
import { SymbologyModelRecord } from 'models/SymbologyModel';
import { SymbologyModel } from 'models/SymbologyModel';
import { LayerAttributeStyle } from 'models/LayerAttributeStyle';
import { LayerRuleStyleRecord } from 'models/LayerRuleStyle';
import './style.scss';
import Modal from 'react-modal';
import { getLegendStructure } from "./getLegendStructure";
import { FiRefreshCw } from 'react-icons/fi';
import { BsExclamationLg } from "react-icons/bs";
import { assetActions, AssetTypeAttributesInfo } from 'actions/asset';
import Draggable from 'react-draggable';

interface Props {
  onNodeChecked: (checkedNodes: Array<any>, currentSelectedNode?: any, refresh?: boolean) => void,
  setLayerStyle: (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => void,
  setLayerVisualisationRules: (key: string, type: string, style: SymbologyModel, layerAttributeStyles: LayerAttributeStyle[]) => void,
  zoomToSection: (key: string) => void,
  bringLayerToTop: (layerKey: string) => void,
  styles: SymbologyModelRecord,
  layerRulesStyles: LayerRuleStyleRecord,
  setCheckedNodesState: (p: any[]) => void,
  handleContextMenuRefreshClicked: (selectedKeys: Key[]) => void,
  setToolbarRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTreeData: React.Dispatch<React.SetStateAction<any[]>>,
  treeData: any[],
  checkedNodesState: any[],
  toolbarRefreshLoading: boolean,
  layerRefreshLoading: never[],
  setLayerRefreshLoading: React.Dispatch<React.SetStateAction<never[]>>,
  showDirection: boolean,
  setShowDirection: (value: boolean) => void
}

declare type Key = string | number;
type FavouriteEntry = [string, any];
const MENU_ID = 'legend-menu';






const Legend: React.FC<Props> = ({
   onNodeChecked,
   setLayerStyle,
   setLayerVisualisationRules,
   bringLayerToTop,
   zoomToSection,
   styles = {},
   layerRulesStyles = {},
   setCheckedNodesState,
   handleContextMenuRefreshClicked,
   setTreeData,
   treeData,
   checkedNodesState,
   layerRefreshLoading,
   setLayerRefreshLoading,
   showDirection,
   setShowDirection
                                 }) => {
  const legendActions = useLegendActions();
  const [loading, setLoading] = useState(true);
  const [symbologyOpened, setSymbologyOpened] = useState(false);
  const [visualisationRulesOpened, setVisualisationRulesOpened] = useState(false);
  const [inDragMode, setInDragMode] = useState(false);
  const [symbologyData, setSymbologyData] = useState({
    key: '',
    category: '',
    title: '',
  });
  const [visualisationRulesData, setVisualisationRulesData] = useState({
    key: '',
    category: '',
    title: '',
    parameterId: -1
  });
  const [checkedKeys, setCheckedKeys] = useState<{
    checked: Key[];
    halfChecked: Key[];
  } | Key[]>(["network-layer"]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const toggleSymbologyOpened = () => setSymbologyOpened(prevState => !prevState);
  const toggleVisualisationRulesOpened = () => setVisualisationRulesOpened(prevState => !prevState);
  const [favourites, setFavourites] = useState<Map<string, any>>(new Map());
  const [favouritesIsAdding, setFavouritesIsAdding] = useState<boolean | null>(null);
  const [hideVisRules, setHideVisRules] = useState<boolean>(false);
  const [VisRulesNoAttr, setVisRulesNoAttr] = useState<string | null>(null);
  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  const {show} = useContextMenu({
    id: MENU_ID,
  });

  

useEffect(() => {
  function handleResize() {
    setWindowSize({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    });
  }

  window.addEventListener('resize', handleResize);
  handleResize();

  return () => window.removeEventListener('resize', handleResize);
}, []);

const customStyles = {
  content: {
    left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
    top: `${(windowSize.innerHeight - 425) / 2}px`,
  }
};

 
  const validateKeys = (keys: Key[], treeData: any[]): Key[] => {
    const validKeys = new Set();
    const traverseNodes = (nodes: any[]) => {
      nodes.forEach(node => {
        validKeys.add(node.key);
        if (node.children) {
          traverseNodes(node.children);
        }
      });
    };
    traverseNodes(treeData);
    return keys.filter(key => validKeys.has(key));
  };

  const getParentKeys = (key: Key, nodes: any[], parentKeys: Key[] = []): Key[] => {
    for (const node of nodes) {
      if (node.children) {
        for (const child of node.children) {
          if (child.key === key) {
            return [...parentKeys, node.key];
          }
          const found = getParentKeys(key, node.children, [...parentKeys, node.key]);
          
          if (found.length) {
            return found;
          }
        }
      }
    }
    return [];
  };
  
  const getAllParentKeys = (checkedKeys: Key[], treeData: any[]): Key[] => {
    const parentKeys = new Set<Key>();
    checkedKeys.forEach(key => {
      const parents = getParentKeys(key, treeData);
      parents.forEach(parentKey => parentKeys.add(parentKey));
    });
    return Array.from(parentKeys);
  };

  const removeFavouritesNode = (nodes: any[]): any[] => {
    return nodes.filter(node => {
      if (node.title === "Favourites" && (!node.children || node.children.length === 0)) {
        return false;
      }
      if (node.children) {
        node.children = removeFavouritesNode(node.children);
      }
      return true;
    });
  };
 
  
  const removeDuplicateNodes = (nodes: any[]): any[] => {
    const seenKeys = new Set();
    return nodes.reduce((acc: any[], node: any) => {
      if (!seenKeys.has(node.key)) {
        seenKeys.add(node.key);
        const newNode = { ...node };
        if (newNode.children) {
          newNode.children = removeDuplicateNodes(newNode.children);
        }
        acc.push(newNode);
      }
      return acc;
    }, []);
  };
  
  const fetchAndSetNetworkAttributes = async (attributeName: string, refreshData: boolean = false) => {
    try {
      const result = await assetActions().getNetworkAttributes(attributeName, refreshData);
      return result;
    } catch (error) {
      console.error('Error fetching network attributes:', error);
      alert('Error fetching network attributes');
      return [];
    }
  };

    const fetchAndSetAssetTypeAttributes = async (itemKey: string, parameterId: number) => {
      try {
        const result = await assetActions().getAssetTypeAttributes(itemKey.split('+')[0], itemKey.split('+')[1], itemKey, parameterId);
    
        let objResult = [];
        if (!Array.isArray(result)) {
          objResult = [result];
        } else {
          objResult = result;
        }
        const res = objResult.filter(x => x.hasLookups || x.dataType === "Integer");
        return res;
      } catch (error) {
        console.error('Error fetching asset type attributes:', error);
        alert('Error fetching asset type attributes');
        return [];
      }
    };
  
  
  useEffect(() => {
    // Load favourites from localStorage when component mounts
    const currentTenant = localStorage.getItem('tenantId');
    const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
    if (!currentTenant || !currentUser) return;

    const savedFavourites = localStorage.getItem(`favourites_${currentTenant}_${currentUser?.username}`);

    if (savedFavourites) {
      const parsedData = JSON.parse(savedFavourites)
      setFavourites(new Map(parsedData));
    }
  }, []);


  useEffect(() => {
    if (treeData.length > 0) {
      // @ts-ignore
      let updatedTreeData = [...treeData];
      
      const currentTenant = localStorage.getItem('tenantId');
      const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any

      const favoritesNodeIndex = updatedTreeData.findIndex(node => node.title === "Favourites");

      if (favouritesIsAdding && favoritesNodeIndex >= 0) {
        const lastEntry = [...favourites.entries()].pop();
        const [lastKey, lastValue] = lastEntry || [undefined, undefined];
        updatedTreeData[1].children = [...updatedTreeData[1].children, {
          ...lastValue,
          key: `${'favourites_' + lastValue?.key}`,
          selectable: true,
          category: "Favourites"
        }]
      } else if (favourites.size > 0 && favoritesNodeIndex === -1) {
        const lastEntry = [...favourites.entries()].pop();
        const [lastKey, lastValue] = lastEntry || [undefined, undefined];

        updatedTreeData.splice(1, 0, {
          key: 'favourites-node',
          title: 'Favourites',
          selectable: false,
          isLeaf: false,
          checkable: false,
          children: [{...lastValue, key: `${'favourites_' + lastValue?.key}`, selectable: true, category: "Favourites"}]
        });

        }
      else if (favourites.size === 0 && favoritesNodeIndex !== -1 && favouritesIsAdding !== null) {
        updatedTreeData.splice(favoritesNodeIndex, 1);
        localStorage.removeItem(`favourites_${currentTenant}_${currentUser?.username}`);
        localStorage.removeItem(`treeData_${currentTenant}_${currentUser?.username}`)
      }

      updatedTreeData = removeFavouritesNode(updatedTreeData);

      setTreeData(updatedTreeData);
      if (currentTenant && currentUser) {
        localStorage.setItem(`favourites_${currentTenant}_${currentUser?.username}`, JSON.stringify([...favourites.entries()]));
        localStorage.setItem(`treeData_${currentTenant}_${currentUser?.username}`, JSON.stringify(treeData[1]));
      }
    }
  }, [favourites]);
 
  useEffect(() => {
    const currentTenant = localStorage.getItem('tenantId');
    const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
    if (currentTenant && currentUser && favourites.size > 0) {
      localStorage.setItem(`treeData_${currentTenant}_${currentUser?.username}`, JSON.stringify(treeData[1]));
    }
  }, [treeData])

  useEffect(() => {
    const onGetLegend = async () => {
      try {
        const legendResponse = await legendActions.getLegend(true);
        const trData = getLegendStructure(legendResponse);
        const currentTenant = localStorage.getItem('tenantId');
        const currentUser = JSON.parse(localStorage.getItem('userData') as any) as any
        const savedTreeData = localStorage.getItem(`treeData_${currentTenant}_${currentUser?.username}`) as any;
        if(savedTreeData){
          trData.splice(1, 0,JSON.parse(savedTreeData))
        }
        setTreeData(removeFavouritesNode(trData));
        setLoading(false);
      } catch (err: any) {

      }
    }
    onGetLegend();
  }, []);

  useEffect(() => {
    let networkKey = treeData.find(data => data.category === "Network" && data.isChecked === true);
    if (networkKey) {
      setCheckedNodesState([networkKey]);
    }
  }, [treeData])

  useEffect(() => {
    let checkedKey = treeData.find(data => data.isChecked === true);
    if (checkedKey) {
      setCheckedNodesState([checkedKey]);
    }
    
  }, [treeData])

  const getNodes = (node: any) => {
    if (node?.children?.length > 0 && node.children[0].isLeaf) {
      return node.children;
    }

    if (node?.children?.length > 0) {
      return node.children.map((x: any) => getNodes(x)).flat();
    }

    return [];
  }

  const nodeSelected = (selectedKeys: Key[], info: any) => {
    if (info.selected) {
      bringLayerToTop(info.node.key.replace('favourites_', ''));

      setSelectedKeys([info.node.key]);

      if (!info.node.checked) {

        onNodeChecked([{
          checked: true,
          title: info.node.title,
          key: info.node.key.replace('favourites_', ''),
          endPoint: info.node.endPoint,
          category: info.category,
        }], {key: info.node.key.replace('favourites_', ''), title: info.node.title, category: info.node.category});
        setCheckedKeys((x) => {
          if (Array.isArray(x)) {
            if (info.node.key.startsWith("favourites_")) {
              return [...x, info.node.key, info.node.key.replace('favourites_', '')]
            } else {
              return [...x, info.node.key, `${'favourites_' + info.node.key}`]
            }
          } else {
            return {
              checked: [...x.checked, info.node.key],
              halfChecked: x.halfChecked,
            }
          }
        })
      } else {
        onNodeChecked([], {key: info.node.key, title: info.node.title, category: info.node.category});
      }
    } else {
      setSelectedKeys([]);
    }
  };

  const nodeChecked = (checked: {
    checked: Key[];
    halfChecked: Key[];
  } | Key[], info: any) => {
    // @ts-ignore
    const favourites_checkedKeys = checkedKeys.filter((item) => item.startsWith('favourites_'))

    // @ts-ignore
    const uniqueCombined = [...new Set([...checked, ...favourites_checkedKeys])];
    // @ts-ignore
    let localChecked = [...uniqueCombined]
    if (info.node.key.startsWith("favourites_")) {
      if (info.checked) {
        // @ts-ignore
        localChecked.push(info.node.key.replace('favourites_', ''))
      } else {
        localChecked = localChecked.filter((item) => item !== info.node.key.replace('favourites_', ''))
        localChecked = localChecked.filter((item) => item !== info.node.key)
      }
    } else {
      if (info.checked) {
        // @ts-ignore
        localChecked.push(`${'favourites_' + info.node.key}`)
      } else {
        localChecked = localChecked.filter((item) => item !== `${'favourites_' + info.node.key}`)
      }
    }
    setCheckedKeys(localChecked);
    if (info.node.isLeaf) {

      if (info.checked === false && info.node.key === (selectedKeys.length > 0 ? selectedKeys[0] : null)) {
        setSelectedKeys([]);
      }

      onNodeChecked([{
        checked: info.checked,
        title: info.node.title,
        key: info.node.key.replace('favourites_', ''),
        endPoint: info.node.endPoint,
        category: info.halfCheckedKeys[0],
      }]);
    } else {
      const nodes = getNodes(info.node).map((item: any) => (
        {
          checked: info.checked,
          title: item.title,
          key: item.key.replace('favourites_', ''),
          endPoint: item.endPoint,
          category: item.category,
        }
      ));
      onNodeChecked(nodes);
    }
  }

  const onNodeDoubleClick = (e: any, node: any) => {
    if (node.isLeaf && node.isChecked) {
      setSymbologyData({
        key: node.key,
        category: node.category,
        title: node.title,
      });
      setSymbologyOpened(true);
    }
  }

  const onRightClick = (info: {
    event: React.MouseEvent;
    node: any;
  }) => {
    if (info.node.isLeaf && !info.node.checked) {
      setCheckedKeys(ch => {
        if (Array.isArray(ch)) {
          if (info.node.key.startsWith("favourites_")) {
            return Array.from(new Set([...ch, info.node.key, info.node.key.replace('favourites_', '')]));
          } else {
            return Array.from(new Set([...ch, info.node.key, `${'favourites_' + info.node.key}`]));
          }
        } else {
          return {
            checked: info.node.key.startsWith("favourites_") ? Array.from(new Set([...ch.checked, info.node.key, info.node.key.replace('favourites_', '')])) : Array.from(new Set([...ch.checked, info.node.key, `${'favourites_' + info.node.key}`])),
            halfChecked: ch.halfChecked,
          }
        }
      });
      onNodeChecked([{
        checked: true,
        title: info.node.title,
        key: info.node.key.replace('favourites_', ''),
        endPoint: info.node.endPoint,
        category: info.node.category,
        // category: info.halfCheckedKeys[0],
      }]);
      bringLayerToTop(info.node.key.replace('favourites_', ''));
      setSelectedKeys([info.node.key.replace('favourites_', '')]);
      setSelectedCategory(info.node.category);
    }
    // Visualisation Rules need to be hidden for certain layers
    setHideVisRules(false);
    if(info.node.key.split('+')[0] === 'Surveys'){
        setHideVisRules(true)
    }
    //
    if (info.node.isLeaf && info.node.checked) {
      show({
        event: info.event,
        props: {
          key: info.node.key.replace('favourites_', ''),
          category: info.node.category,
          title: info.node.title,
          parameterId: info.node.parameterId
        }
      });
      bringLayerToTop(info.node.key.replace('favourites_', ''));
      setSelectedKeys([info.node.key.replace('favourites_', '')]);
      setSelectedCategory(info.node.category);
    }
  };

  const onDragEnd = () => setInDragMode(false);

  const onDragStart = () => setInDragMode(true);

  const handleOpenSymbology = (ev: any) => {
    setSymbologyData({
      key: ev.props.key,
      category: ev.props.category,
      title: ev.props.title,
    });
    setSymbologyOpened(true);
  };

  const handleOpenVisualisationRules = async (ev: any) => {

    setVisualisationRulesData({
      key: ev.props.key,
      category: ev.props.category,
      title: ev.props.title,
      parameterId: ev.props.title
    });
    try {
      let result = [];
      if (ev.props.category === 'Network') {
        result = await fetchAndSetNetworkAttributes(ev.props.title);
      } else {
        result = await fetchAndSetAssetTypeAttributes(ev.props.key, ev.props.parameterId);
      }

      if (result.length === 0) {
        setVisualisationRulesOpened(false);
        setVisRulesNoAttr('No attributes found for this layer');
        return;
      }
        setVisualisationRulesOpened(true);
      } catch (error) {
        console.error('Error fetching attributes:', error);
        alert('Error fetching attributes');
      }
    };

  const closeModal = () => {
    setVisRulesNoAttr(null);
  };

  const handleZoomToSection = (ev: any) => {
    zoomToSection(ev.props.key);
  };

  const findParentNode = (key: string, nodes: any, category: any = null) => {
    for (const node of nodes) {
      if (node.children) {
        const currentCategory = category || node;
        for (const child of node.children) {
          if (child.key === key) {
            return {
              ...node,
              categoryName: currentCategory?.title
            };
          }
          const found: any = findParentNode(key, node.children, currentCategory);
          if (found) return found;
        }
      }
    }
    return null;
  }

  const handleImageClick = (nodeData: any, event: any) => {
    event.stopPropagation();

    const newFavorites = new Map(favourites);

    const parentNode = findParentNode(nodeData.key, treeData);
    const parentName = parentNode ? parentNode.title : 'Root';


    if (newFavorites.has(nodeData.key) || newFavorites.has(`${"favourites_" + nodeData.key}`)) {
      // @ts-ignore
      const updatedTreeData = [...treeData];
      const filteredTreeItems = updatedTreeData[1].children.filter((item: any) => {
        return item.key !== nodeData.key && item.key !== `favourites_${nodeData.key}`;
      });
      updatedTreeData[1].children = filteredTreeItems
      newFavorites.delete(nodeData.key);
      newFavorites.delete(`${"favourites_" + nodeData.key}`)
      newFavorites.delete(nodeData.key.replace('favourites_', ''));
      setFavouritesIsAdding(false);
      setTreeData(updatedTreeData)
    } else {

      newFavorites.set(nodeData.key, nodeData);
      const localNode = {...nodeData, title: `${nodeData.title} (${parentName})`, categoryName: parentNode.categoryName}
      newFavorites.set(`${"favourites_" + nodeData.key}`, localNode)
      setFavouritesIsAdding(true)
    }
    setFavourites(newFavorites);
  };

  const titleRender = (nodeData: any) => {
    let isSelectedKeysRefreshing: { [key: string]: { isLoading: boolean, hasError: boolean } } = {};
    
    if (layerRefreshLoading.hasOwnProperty(nodeData.key)) {
      isSelectedKeysRefreshing[nodeData.key] = layerRefreshLoading[nodeData.key];
    }

    const isChecked = Array.isArray(checkedKeys)
    ? checkedKeys.includes(nodeData.key)
    : checkedKeys.checked.includes(nodeData.key);

    const parentKeys = getAllParentKeys(Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked, treeData);
    const isParentChecked = parentKeys.includes(nodeData.key);

    const isSelected = (isChecked || isParentChecked) && nodeData.title !== "Network";

    return (
      <div id="favourite_tooltip" data-tip="favourite_tooltip" data-for="favourite_tooltip" className='title_render--container'>
        <div className={`title_render--container_title ${isSelected ? 'selected-title' : ''}`} title={`${nodeData.title} ${nodeData.category === 'Favourites' ? `\n~${nodeData.categoryName}~` : ''}`}>{nodeData.title}</div>
        {nodeData.isLeaf &&
          <div style={{display: "flex", alignItems: 'center', gap: '5px'}}>
            {/* {isSelectedKeysRefreshing && <FiRefreshCw className='rotating'/>} */}

            {(isSelectedKeysRefreshing?.[nodeData.key]?.isLoading || isSelectedKeysRefreshing?.['network-layer']?.isLoading) && (
            <FiRefreshCw className='rotating' />
            )}
            {(isSelectedKeysRefreshing?.[nodeData.key]?.hasError || isSelectedKeysRefreshing?.['network-layer']?.hasError) && (
            <BsExclamationLg color='red' size={20} />
            )}

            {favourites.has(nodeData.key) ?
              <AiFillStar className={`favourites_star_img`} color="#FFC845" size={19}
              onClick={(event: any) => handleImageClick(nodeData, event)}/>
              :
              <AiOutlineStar className={`favourites_star_img`} size={19}
              onClick={(event: any) => handleImageClick(nodeData, event)}/>
            }
          </div>
        }
      </div>
    );
  };
  
const VisRulesNoAttrModal = ({ VisRulesNoAttrText, title, onClose, isOpen }: { VisRulesNoAttrText: string | null, title: string, onClose: () => void, isOpen: boolean }) => {
  if (!VisRulesNoAttrText) return null;

  return (
    <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    bodyOpenClassName={null}
    portalClassName="react-modal-container"
    overlayClassName="react-modal-overlay"
    className="react-modal-wrapper"
    shouldCloseOnOverlayClick={false}
    shouldCloseOnEsc={false}
    style={customStyles}
  >
    {/* <Draggable
        enableUserSelectHack={false}
        handle=".modal-title-overlay"
        bounds="body"
      > */}
  <div className='modal-container'></div>
    <div className="react-modal symbology-modal">
    <div className="modal-header" style={{cursor:'auto'}}>
    <div className="modal-title-overlay" >Visualisation rules: {title}</div>
            <div className="modal-header-buttons">
              <div className="modal-close" onClick={onClose}>
                <MdClose />
              </div>
            </div>
          </div>
      <div className="modal-content">
        <p style={{margin:'auto'}}>{VisRulesNoAttrText}</p>
      </div>
    </div>
    {/* </Draggable> */}
    </Modal >

  );
};

  return (
    <>
      {symbologyOpened && (<Symbology
        isOpen={symbologyOpened}
        closeModal={toggleSymbologyOpened}
        itemKey={symbologyData.key}
        category={symbologyData.category}
        title={symbologyData.title}
        setLayerStyle={setLayerStyle}
        currentWidth={styles[symbologyData.key]?.currentWidth}
        currentSize={styles[symbologyData.key]?.currentSize}
        currentLineStyle={styles[symbologyData.key]?.currentLineStyle}
        currentMarkerStyle={styles[symbologyData.key]?.currentMarkerStyle}
        currentColor={styles[symbologyData.key]?.currentColor}
        currentAngle={styles[symbologyData.key]?.currentAngle}
        layerType={styles[symbologyData.key]?.type}
        showDirection={showDirection}
        setShowDirection={setShowDirection}
      />)}
      {visualisationRulesOpened ? (<VisualisationRule
        isOpen={visualisationRulesOpened}
        closeModal={toggleVisualisationRulesOpened}
        itemKey={visualisationRulesData.key}
        category={visualisationRulesData.category}
        title={visualisationRulesData.title}
        parameterId={visualisationRulesData.parameterId}
        layerType={styles[visualisationRulesData.key]?.type}
        defaultStyle={styles[visualisationRulesData.key]}
        layerRulesStyles={layerRulesStyles[visualisationRulesData.key]}
        setLayerVisualisationRules={setLayerVisualisationRules}
        setVisualisationRulesOpened={setVisualisationRulesOpened} 
        showDirection={showDirection}
        setShowDirection={setShowDirection}
      />):
      <VisRulesNoAttrModal VisRulesNoAttrText={VisRulesNoAttr} title={visualisationRulesData.title} onClose={closeModal} isOpen/>}
      
      {!inDragMode && (<Menu id={MENU_ID}>
        <Item onClick={handleOpenSymbology}><MdColorLens/>Symbology</Item>
        {!hideVisRules && <Item onClick={handleOpenVisualisationRules}><MdOutlineRule/>Visualisation Rules</Item>}
        <Item onClick={handleZoomToSection}><MdFullscreenExit/>Zoom to extent</Item>
        <Item onClick={() => {
          handleContextMenuRefreshClicked(selectedKeys);
          setLayerRefreshLoading((prevState) => ({
            ...prevState,
            [selectedKeys.toString()]: true,
          }));
        }}
        >
          <FiRefreshCw size={10} style={{marginRight: '5px'}}/>Refresh</Item>
      </Menu>)}
      <div className="xa-legend-container">
        <Tree
          showLine
          selectable
          checkable
          defaultExpandParent={false}
          autoExpandParent={false}
          defaultExpandAll={false}
          showIcon={false}
          treeData={removeDuplicateNodes(treeData)}
          onCheck={nodeChecked}
          onSelect={nodeSelected}
          checkedKeys={validateKeys(Array.isArray(checkedKeys) ? checkedKeys : checkedKeys.checked, treeData)}
          selectedKeys={validateKeys(Array.isArray(selectedKeys) ? selectedKeys : [], treeData)}
          onRightClick={onRightClick}
          onDoubleClick={onNodeDoubleClick}
          titleRender={titleRender}
          defaultExpandedKeys={["favourites-node"]}
        />
      </div>
    </>
  );
}
export default Legend;
