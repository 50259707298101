import React from 'react';
import dayjs from 'dayjs'
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'containers/App/App';
// import reportWebVitals from './reportWebVitals';
import './index.scss';
import { store } from './state/store'
import { Provider } from 'react-redux'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


dayjs.locale('es') // use Spanish locale globally


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch(err => {
        console.log('ServiceWorker registration failed: ', err);
      });
  });
}


// ------ This will remove all the service workers --------------------------
// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//   for (let registration of registrations) {
//     registration.unregister();
//   }
// });


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App/>
    </Provider>
  </BrowserRouter>
);


export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'a006d5e0-6f3a-40a4-a3a9-26637f2d60b4',
    enableAutoRouteTracking: false,
  },
});

try {
  appInsights.loadAppInsights();
} catch (error) {
  console.error('Error loading Application Insights:', error);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
