import React from 'react';
import { RgbaColor } from "react-colorful";
import {ReactComponent as DashDotDot} from './images/symbols-sls-long-dash-dot-dot.svg';
import {ReactComponent as ShortDashDotDot} from './images/symbols-sls-short-dash-dot-dot.svg';
import {ReactComponent as DashDot} from './images/symbols-sls-dash-dot.svg';
import {ReactComponent as Dash} from './images/symbols-sls-dash.svg';
import {ReactComponent as Dot} from './images/symbols-sls-dot.svg';
import {ReactComponent as LongDashDot} from './images/symbols-sls-long-dash-dot.svg';
import {ReactComponent as LongDash} from './images/symbols-sls-long-dash.svg';
import {ReactComponent as ShortDashDot} from './images/symbols-sls-short-dash-dot.svg';
import {ReactComponent as ShortDash} from './images/symbols-sls-short-dash.svg';
import {ReactComponent as ShortDot} from './images/symbols-sls-short-dot.svg';
import {ReactComponent as Solid} from './images/symbols-sls-solid.svg';

const renderType = (type: string, color: RgbaColor) => {
  const col = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  switch (type) {
    case 'symbols-sls-long-dash-dot-dot':
      return <DashDotDot style={{ height: '35px', width: '225px', fill: col }} />
    case 'symbols-sls-short-dash-dot-dot':
      return <ShortDashDotDot style={{ height: '35px', width: '225px', fill: col }} />
    case 'symbols-sls-dash-dot':
      return <DashDot style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-dash':
      return <Dash style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-dot':
      return <Dot style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-long-dash-dot':
      return <LongDashDot style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-long-dash':
      return <LongDash style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-short-dash-dot':
      return <ShortDashDot style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-short-dash':
      return <ShortDash style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-short-dot':
      return <ShortDot style={{ height: '35px', width: '225px', fill: col }} />;
    case 'symbols-sls-solid':
      return <Solid style={{ height: '35px', width: '225px', fill: col }} />;
    default:
      return null;
  }
};

export default renderType;
