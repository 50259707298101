import React from 'react';
import { RgbaColor } from "react-colorful";
import {ReactComponent as Circle} from './images/symbols-sms-circle.svg';
import {ReactComponent as Cross} from './images/symbols-sms-cross.svg';
import {ReactComponent as Diamond} from './images/symbols-sms-diamond.svg';
import {ReactComponent as Square} from './images/symbols-sms-square.svg';
import {ReactComponent as Triangle} from './images/symbols-sms-triangle.svg';
import {ReactComponent as X} from './images/symbols-sms-x.svg';


const renderMarkerType = (type: string, color: RgbaColor) => {
  const col = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  switch (type) {
    case 'symbols-sls-circle':
      return <Circle style={{ height: '30px', width: '30px', fill: col }} />;
    case 'symbols-sls-cross':
      return <Cross style={{ height: '30px', width: '30px', fill: col }} />;
    case 'symbols-sls-diamond':
      return <Diamond style={{ height: '30px', width: '30px', fill: col }} />;
    case 'symbols-sls-square':
      return <Square style={{ height: '30px', width: '30px', fill: col }} />;
    case 'symbols-sls-triangle':
      return <Triangle style={{ height: '30px', width: '30px', fill: col }} />;
    case 'symbols-sls-x':
      return <X style={{ height: '30px', width: '30px', stroke: col }} />;
    default:
      return null;
  }
};

export default renderMarkerType;
