import React from 'react'
import ChartHub from './ChartHub';
import ImageHub from './ImageHub';
import ToggleSwitchWithText from 'components/ToggleSwitchWithText';

type AssetHubOptionsProps = {
    FAKE_BRIDGES: Array<{ name: string; src: string }>
  id: number,
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
};

const ASSET_HUB_OPTIONS = [
    { value: 'Charts', label: 'Charts' },
    // { value: 'Images', label: 'Images' }
  ]

const AssetHubOptions: React.FC<AssetHubOptionsProps> = ({
   FAKE_BRIDGES,
   id,
   wasAssetHubRefreshedClicked,
   wasAssetHubRefreshLoading
  }) => {
    const [selectedAssetHub, setSelectedAssetHub] = React.useState<string>(ASSET_HUB_OPTIONS[0].value);
    const [requestType, setRequestType] = React.useState<string>('inspections'); //we need to get an endpoint for this values and remove hardcoded ones.


    const onChangeAssetHub = (sp: string) => {
        setSelectedAssetHub(sp);
    };
    const onChangeRequestType = (sp: string) => {
        setRequestType(sp)
    }

    return (
        <div className='xa_asset_hub--options_container'>
            <div className="xa_asset_hub--options-select">
                <select name="assetHub" id="assetHub" onChange={(e) => onChangeRequestType(e.target.value)}>
                     <option value="inspections">
                         Inspection Breakdown
                    </option>
                  <option value="treatments">
                        Treatment Breakdown
                    </option>
                </select>
                <ToggleSwitchWithText onChange={onChangeAssetHub} options={ASSET_HUB_OPTIONS} selectedValue={selectedAssetHub} name="change_assetHub"  />
            </div>
            {selectedAssetHub === ASSET_HUB_OPTIONS[0].value ?
                <ChartHub
                  id={id}
                  requestType={requestType}
                  wasAssetHubRefreshedClicked={wasAssetHubRefreshedClicked}
                  wasAssetHubRefreshLoading={wasAssetHubRefreshLoading} /> :
                <ImageHub images={FAKE_BRIDGES} />
            }
        </div>
    )
}
export default AssetHubOptions;
