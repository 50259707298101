import React, { useState, useEffect } from 'react';
import { get } from 'utils/request';
import { useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import { create, test, enforce } from 'vest';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from "react-router-dom";
import { useNotification } from 'rc-notification';
import Button from 'components/Button';
import { FormValues } from 'models/Login';
import { useUserActions } from 'actions/user';
import 'rc-notification/assets/index.css';
import './style.scss';
import axios from 'axios'; 

const validationSuite = create((data = {}) => {
  test('username', 'Username is required', () => {
    enforce(data.username).isNotEmpty();
    enforce(data.username).isString();
  });

  test('password', 'Password is required', () => {
    enforce(data.password).isNotEmpty();
  });
});

function Login() {
  const userActions = useUserActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [tenants, setTenants] = useState<string[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
  const location = useLocation();
  const [notice, contextHolder] = useNotification({ closable: true });
  const [showPdfButton, setShowPdfButton] = useState(true);
  const [userIp, setUserIp] = useState<string | null>(null);
  // const [loadingIpCheck, setLoadingIpCheck] = useState(true); 

  const ipApiUrl = 'https://ipapi.co/json'; 

  useEffect(() => {
    if (location.state == 'fromRegister') {
      notice.open({
        content: "Account registered succesfully",
      });
    }
  }, [location]);

  useEffect(() => {
    if (location.state?.selectedTenant) {
      setSelectedTenant(location.state.selectedTenant);
    }
  }, [location]);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await userActions.getTenants();
        setTenants(response);
        setSelectedTenant((prev) => prev || response[0]); 
      } catch (error) {
        console.error('Error fetching tenants:', error);
        setTenants([]);
        setSelectedTenant(null);
      }
    };
    fetchTenants();
  }, []);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await get<{ ip: string }>(ipApiUrl);
        const ip = response.data.ip;
        setUserIp(ip);
  
        const storedData = JSON.parse(localStorage.getItem('ipData') || '{}');
        const ipData = storedData[ip];
  
        if (ipData?.viewed && new Date(ipData.expiration) > new Date()) {
          setShowPdfButton(true); 
        } else {
          setShowPdfButton(false); 
  
          // set a new expiration date (5 days from now)
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 5);
  
          storedData[ip] = { 
            viewed: true,
            expiration: expirationDate.toISOString(),
          };
          localStorage.setItem('ipData', JSON.stringify(storedData));
        }
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    fetchIpAddress();
  }, []);

  const handleTenantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTenant(event.target.value);
  };

  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
    resolver: vestResolver(validationSuite),
  });

  const toggleShowPassword = () => setShowPassword(prev => !prev);

  const onLogin = async (data: FormValues) => {
    try {
      await userActions.login({ ...data, tenantId: selectedTenant });
      setGenericErrors(null);
    } catch (error: any) {
      console.error('Login error:', error);
      setGenericErrors(error?.response?.data)
    }
  };

  const onSubmit = handleSubmit(onLogin);

  return (
    <>
      {contextHolder}
      <div className="xa-auth-page-form-wrapper">
        <form>
          <div className="xa-form-field">
            <label htmlFor="groups" className="required">Tenants</label>
            <select name="groups" id="groups" onChange={handleTenantChange}>
              {tenants?.map((tenant) => (
                <option value={tenant} key={tenant} selected={selectedTenant === tenant}>
                  {tenant}
                </option>))}
            </select>
          </div>
          <div className="xa-form-field">
            <label htmlFor="username" className="required">Username</label>
            <input type="text" id="username" autoComplete="username" required {...register("username")} className={errors?.username?.message && 'invalid'} />
            <div className="xa-auth-page-form-error">
              {errors?.username?.message && (errors.username.message)}
            </div>
          </div>
          <div className="xa-form-field">
            <label htmlFor="password" className="required">Password</label>
            <div className="relative">
              <input type={showPassword ? "text" : "password"} id="password" autoComplete="password" required {...register("password")} className={errors?.password?.message && 'invalid'} />
              <div className="xa-auth-page-form-password-view" onClick={toggleShowPassword}>
                {showPassword ? (<FiEye />) : (<FiEyeOff />)}
              </div>
            </div>
            <div className="xa-auth-page-form-error">
              {errors?.password?.message && (errors.password.message)}
            </div>
          </div>
          <div className="xa-auth-page-form-link">
            <Link to="/register" state={{ selectedTenant }}>Register?</Link>
          </div>
          {/* {genericErrors && ( */}
            <div className="xa-auth-page-form-errors">
              {genericErrors}
            </div>
          {/* )} */}
          <Button onClick={onSubmit} color="primary" full isButton>
            Sign in
          </Button>
          {showPdfButton && (
          <div className="xa-auth-page-form-pdf-link">
            <Button color="update" full isButton
              onClick={() => window.open('/XAE_Newsletter_2.pdf', '_blank')}
            >
              Newsletter 🎉
            </Button>
          </div>
          )}
        </form>
      </div>
    </>
  );
}

export default Login;