import {
  MdInsights,
  MdStraighten,
  MdLayers
} from 'react-icons/md';

export const NavBarSecondaryMenu = [
  {
    title: 'Measure',
    path: '/test',
    icon: <MdStraighten />
  },
  {
    title: 'Basemaps',
    path: '/test',
    icon: <MdLayers />
  },
  {
    title: 'Legend',
    path: '/test',
    icon: <MdInsights />
  },
  // {
  //   title: 'Search in current layer',
  //   path: '/test',
  //   icon: <MdOutlineSearch />
  // },
  // {
  //   title: 'Zoom to section',
  //   path: '/test',
  //   icon: <MdFullscreenExit />
  // },
  // {
  //   title: 'Zoom to current layer',
  //   path: '/test',
  //   icon: <MdPageview />
  // },
  // {
  //   title: 'Zoom to previous extents',
  //   path: '/test',
  //   icon: <MdManageSearch />
  // },
  // {
  //   title: 'Background mapping',
  //   path: '/test',
  //   icon: <MdWallpaper />
  // },
  // {
  //   title: 'Document store',
  //   path: '/test',
  //   icon: <MdFolder />
  // },
  // {
  //   title: '360° image viewer',
  //   path: '/test',
  //   icon: <Md360 />
  // }
];
