import React from 'react'

type EmptyRequestDisplayProps = {};

const EmptyRequestDisplay: React.FC<EmptyRequestDisplayProps> = () => {
    return (
        <div className="xa-no-data">
            <div className="image"></div>
            <div className="text">No data found!</div>
        </div>
    );
    
}
export default EmptyRequestDisplay;