import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ThemeState {
  theme: 'light' | 'dark'
}

const initialState: ThemeState = {
    theme: 'dark'
}

export const counterSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeState>) => {
      state.theme = action.payload.theme
    },
    
  },
})

export const { setTheme} = counterSlice.actions

export default counterSlice.reducer
