import React, { Fragment, useState, useEffect } from 'react';
import { HiChevronRight, HiChevronDown, HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import XALogoMark from 'assets/images/xa-logomark.webp';
import XALogoDark from 'assets/images/xa-logo-w.webp';
import './style.scss';
import {
  MdDashboard
} from 'react-icons/md';
import { FaUserGear } from "react-icons/fa6";
import { get } from 'utils/request';
import { UserDetails } from 'models/UserDetails';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';


interface Props {
  displayModal: (title: string) => void,
  isExpanded: boolean | null,
  setIsExpanded: (value: boolean) => void,
  toggleNavBarX: () => void;
  // onNavBarClick: () => void;
}

const NAVBAR_PRIMARY_MENU = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <MdDashboard />,
    children: [
      {
        title: 'Major asset group overview',
        path: '/chart'
      }
    ]
  }
]

const NavBar: React.FC<Props> = ({
  displayModal,
  isExpanded,
  setIsExpanded,
  toggleNavBarX
  // onNavBarClick
}) => {
  const [isSubmenuOpened, setSubmenuOpened] = useState({} as { [k: string]: any });
  const [navBarMenu,setNavBarMenu] = useState<typeof NAVBAR_PRIMARY_MENU>(NAVBAR_PRIMARY_MENU);
  const username = useSelector((state: {user: UserDetails}) => state.user.username)


  const navigate = useNavigate();
  const location = useLocation();

  const isAdmin = async () =>{
   const user = await get<UserDetails>(`users/${username}`);
   if(user.data.admin){
      setNavBarMenu([...navBarMenu, {
        title: 'Administration Toolkit',
        path: '/administration-toolkit',
        icon: <FaUserGear />,
        children: []
      },])
   }
  }

  useEffect(() => {
    const navbarExpanded = localStorage.getItem('navbarExpanded');
    const submenuState = localStorage.getItem('submenuState');
    setIsExpanded(navbarExpanded === 'true');
    if (submenuState) {
      setSubmenuOpened(JSON.parse(submenuState));
    }
    isAdmin()
  }, []);

  useEffect(() => {
    if (isExpanded !== null) {
      localStorage.setItem('navbarExpanded', isExpanded.toString());
      localStorage.setItem('submenuState', JSON.stringify(isSubmenuOpened));
    }
  }, [isSubmenuOpened, isExpanded]);

  const toggleSubmenu = (itemName: string) => {
    setSubmenuOpened(
      sub => {
        if (sub[itemName] !== undefined) {
          sub[itemName] = !sub[itemName]
        }
        else {
          sub[itemName] = true;
        }
        return {
          ...sub,
        };
      }
    );
  };

  // const toggleNavBar = () => {
  //   if (isExpanded) {
  //     setIsExpanded(false);
  //     setSubmenuOpened({});
  //     return;
  //   }
  //   setIsExpanded(true);
  // }

  const handlePrimaryMenuClick = (event: React.MouseEvent<HTMLElement>, title: string) => {
    event.preventDefault();
    if (title === 'Major asset group overview') {
      displayModal('Major asset group overview');
      setIsExpanded(false);
      if (location.pathname === '/administration-toolkit') {
        navigate('/chart');
      }
    }
  }

  return (
    <div className={`xa-navbar${isExpanded ? ' expanded' : ' collapsed'}`} >
      {navBarMenu.map((item) => {
        return (
          <Fragment key={item.title}>
            <div className={`xa-navbar-item${isSubmenuOpened[item.title] ? ' submenu-opened' : ''}`} data-tip={item.title} data-for={item.title}>
              <NavLink
                to={item.path}
                onClick={(e) => {
                  if (item.children.length > 0) {
                    e.preventDefault(); // Prevent default navigation
                    toggleSubmenu(item.title); // Toggle submenu
                  }
                }}
                className={({ isActive }) => {
                  if (item.path === '/' && window.location.pathname.startsWith('/administration-toolkit')) {
                    return ''; // Not active
                  }
                  return isActive ? 'active' : '';
                }}
              >
                <span className="xa-navbar-item-icon">{item.icon}</span>
                <span className="xa-navbar-item-text">{item.title}</span>
                {item.children.length > 0
                  && <span className="xa-navbar-item-parent-icon">{isSubmenuOpened[item.title] ? <HiChevronDown /> : <HiChevronRight />}</span>
                }
              </NavLink>
              <div className="xa-navbar-submenu">
                {(!isExpanded && item.children.length > 0) &&
                  <div className="xa-navbar-submenu-title">{item.title}</div>
                }

                {item.children.map((subitem, title) => {
                  return (
                    <NavLink to={subitem.path} key={title} onClick={(e) => handlePrimaryMenuClick(e, subitem.title)} className={({ isActive }) =>
                      isActive ? 'active' : ''}>
                      <span>{subitem.title}</span>
                    </NavLink>
                  );
                })}
              </div>
            </div>
            {(!isExpanded && item.children.length === 0) &&
              <ReactTooltip
                id={item.title}
                place="right"
                type="dark"
                effect="solid"
                backgroundColor="var(--xa-black)"
                textColor="var(--xa-white)"
              />
            }
          </Fragment >
        )
      })
      }

      <div className={`xa-navbar-logo ${!isExpanded && 'collapsed'}`}>
        <img src={isExpanded ? XALogoDark : XALogoMark} alt="XA Logo" />
        {isExpanded &&
          <>
            <span className="xa-navbar-logo-poweredby">powered by</span>
            <a href="https://xais.co.uk/" target="_blank" rel="noreferrer noopener">XAIS-PTS</a>
          </>
        }
      </div>

      <div className="xa-navbar-toggle" onClick={toggleNavBarX}>
        <span className="xa-navbar-toggle-icon">
          {isExpanded ? <HiChevronDoubleLeft /> : <HiChevronDoubleRight />}
        </span>
        <span className="xa-navbar-toggle-text">Collapse</span>
      </div>
    </div>
  );
}

export default NavBar;
