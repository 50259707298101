import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import renderType from './lineTypesRenderer';
import renderMarkerType from './makerTypesRenderer';
import { MdClose } from 'react-icons/md';
import { TbArrowsMinimize, TbArrowsMaximize } from 'react-icons/tb';
import Draggable from 'react-draggable';
import { RgbaColorPicker, RgbaColor } from "react-colorful";
import Slider from 'rc-slider';
import Select, { SingleValue } from 'react-select';
import InputNumber from 'rc-input-number';
import 'rc-slider/assets/index.css';
import 'rc-input-number/assets/index.css';

const lineTypes = [
  { value: 'symbols-sls-short-dash-dot-dot', label: '' },
  { value: 'symbols-sls-long-dash-dot-dot', label: '' },
  { value: 'symbols-sls-dash-dot', label: '' },
  { value: 'symbols-sls-dash', label: '' },
  { value: 'symbols-sls-dot', label: '' },
  { value: 'symbols-sls-long-dash-dot', label: '' },
  { value: 'symbols-sls-long-dash', label: '' },
  { value: 'symbols-sls-short-dash-dot', label: '' },
  { value: 'symbols-sls-short-dash', label: '' },
  { value: 'symbols-sls-short-dot', label: '' },
  { value: 'symbols-sls-solid', label: '' },
];

const makerTypes = [
  { value: 'symbols-sls-circle', label: '' },
  { value: 'symbols-sls-cross', label: '' },
  { value: 'symbols-sls-diamond', label: '' },
  { value: 'symbols-sls-square', label: '' },
  { value: 'symbols-sls-triangle', label: '' },
  { value: 'symbols-sls-x', label: '' },
];

interface SymbologyProps {
  isOpen: boolean,
  closeModal: () => void,
  itemKey: string,
  category: string,
  title: string,
  setLayerStyle: (key: string, color: RgbaColor, width: number, size: number, lineStyle: string, markerStyle: string, angle: number) => void,
  minWidth?: number,
  maxWidth?: number,
  minSize?: number,
  maxSize?: number,
  step?: number,
  layerType?: string,
  currentWidth?: number,
  currentSize?: number,
  currentLineStyle?: string,
  currentMarkerStyle?: string,
  currentColor?: RgbaColor,
  currentAngle?: number,
  showDirection: boolean,
  setShowDirection: (value: boolean) => void,
}

const Symbology: React.FC<SymbologyProps> = ({
  isOpen = false,
  closeModal = () => { },
  itemKey,
  setLayerStyle,
  minWidth = 1,
  maxWidth = 22,
  minSize = 1,
  maxSize = 60,
  step = 1,
  layerType = 'both',
  category,
  title,
  currentWidth = 4,
  currentSize = 22,
  currentLineStyle = 'solid',
  currentMarkerStyle = 'circle',
  currentColor = { r: 48, g: 54, b: 150, a: 1 },
  currentAngle = 0,
  showDirection,
  setShowDirection
}) => {
  const [isModalMinimized, setModalMinimized] = useState<boolean>(false);
  const [marks, setMarks] = useState<any>({});
  const [pointMarks, setPointMarks] = useState<any>({});
  const [layerWidth, setLayerWidth] = useState<number>(currentWidth);
  const [layerSize, setLayerSize] = useState<number>(currentSize);
  const [angle, setAngle] = useState<number | null>(currentAngle);
  const toggleModalState = () => setModalMinimized(prevState => !prevState);
  const [color, setColor] = useState<RgbaColor>(currentColor);
  const [lineStyle, setLineStyle] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>({
    label: `symbols-sls-${currentLineStyle}`,
    value: `symbols-sls-${currentLineStyle}`,
  });
  const [markerStyle, setmarkerStyle] = useState<SingleValue<{
    label: string,
    value: string,
  }> | null>({
    label: `symbols-sls-${currentMarkerStyle}`,
    value: `symbols-sls-${currentMarkerStyle}`,
  });
  
  const [localShowDirection, setLocalShowDirection] = useState<boolean>(showDirection);
  // const [localShowDirection, setLocalShowDirection] = useState<boolean>(() => {
  //   const cachedValue = localStorage.getItem('showDirection');
  //   return cachedValue !== null ? JSON.parse(cachedValue) : showDirection;
  // });

  // useEffect(() => {
  //   localStorage.setItem('showDirection', JSON.stringify(localShowDirection));
  // }, [localShowDirection]);

  const onSubmit = () => {
    const lineSt = lineStyle?.value?.replace('symbols-sls-', '') || '';
    const markerSt = markerStyle?.value?.replace('symbols-sls-', '') || '';
    setLayerStyle(itemKey, color, layerWidth, layerSize, lineSt, markerSt, angle || 0);
    setShowDirection(localShowDirection);
  }

  useEffect(() => {
    let current = minWidth;
    const newMarks: any = {};
    while (current <= maxWidth) {
      var index = `${current}`;
      newMarks[index as keyof typeof newMarks] = `${current}`;
      current += step * 3;
    }
    setMarks(newMarks);
  }, [minWidth, maxWidth, step]);

  useEffect(() => {
    let current = minWidth;
    const newMarks: any = {};
    while (current <= maxSize) {
      var index = `${current}`;
      newMarks[index as keyof typeof newMarks] = `${current}`;
      current += step * 8;
    }
    setPointMarks(newMarks);
  }, [minSize, maxSize, step]);

  const onSliderChange = (size: number | number[]) => {
    if (Array.isArray(size)) {
      if (size.length > 0) {
        setLayerWidth(size[0]);
      }
    } else {
      setLayerWidth(size);
    }
  };

  const onMarkSliderChange = (size: number | number[]) => {
    if (Array.isArray(size)) {
      if (size.length > 0) {
        setLayerSize(size[0]);
      }
    } else {
      setLayerSize(size);
    }
  };

  const onLineStyleChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setLineStyle(newVal);
  }

  const onMakerStyleChange = (newVal: SingleValue<{
    label: string,
    value: string,
  }>) => {
    setmarkerStyle(newVal);
  }

  const [windowSize, setWindowSize] = useState({
    innerWidth: 0,
    innerHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const customStyles = {
    content: {
      left: `${(windowSize.innerWidth - 378 - 203) / 2}px`,
      top: `${(windowSize.innerHeight - 425) / 2}px`,
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalShowDirection(!localShowDirection);
  };
  
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={customStyles}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-symbology"
        bounds="body"
      >

        <div className={`react-modal symbology-modal ${isModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header modal-symbology">
            <div className="modal-title">Symbology: {title}</div>
            <div className="modal-header-buttons">
              <div className="modal-control" onClick={toggleModalState}>
                {isModalMinimized ? <TbArrowsMaximize /> : <TbArrowsMinimize />}
              </div>
              <div className="modal-close" onClick={() => closeModal()}>
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            {category === 'Network' && (
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={localShowDirection}
                    onChange={handleCheckboxChange}
                  />
                  Show Direction
                </label>
              </div>
            )}
            <RgbaColorPicker color={color} onChange={setColor} />
            {(layerType === 'simple-line' || layerType === 'both') &&
              (
                <>
                  <div style={{ marginTop: '16px' }}>
                    <strong>Line</strong>
                    <Slider
                      min={minWidth}
                      max={maxWidth}
                      step={step}
                      value={layerWidth}
                      marks={marks}
                      onChange={onSliderChange}
                    />
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <Select
                      options={lineTypes}
                      onChange={onLineStyleChange}
                      value={lineStyle}
                      className={'custom_select'}
                      menuPlacement="top"
                      classNamePrefix="custom_select"
                      formatOptionLabel={lineType => (
                        <div>
                          {renderType(lineType.value, color)}
                        </div>
                      )}
                    />
                  </div>
                </>
              )}
            {(layerType === 'simple-marker' || layerType === 'both') &&
              (
                <>
                  <div style={{ marginTop: '16px' }}>
                    <strong>Point</strong>
                    <Slider
                      min={minSize}
                      max={maxSize}
                      step={step}
                      value={layerSize}
                      marks={pointMarks}
                      onChange={onMarkSliderChange}
                    />
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <Select
                      options={makerTypes}
                      value={markerStyle}
                      className={'custom_select'}
                      classNamePrefix="custom_select"
                      menuPlacement="top"
                      onChange={onMakerStyleChange}
                      formatOptionLabel={makerType => (
                        <div>
                          {renderMarkerType(makerType.value, color)}
                        </div>
                      )}
                    />
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <strong>Angle</strong>
                    <InputNumber
                      min={0}
                      max={360}
                      step={1}
                      value={angle}
                      onChange={setAngle}
                    />
                  </div>
                </>
              )}
          </div>
          <div className="modal-footer">
            <Button onClick={onSubmit} color="primary" full isButton>
              Apply
            </Button>
          </div>
        </div>
      </Draggable>
    </Modal >
  );
}

export default Symbology;
