import { UserDetails } from 'models/UserDetails';
import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get } from 'utils/request';

const isAdmin = async (username: string): Promise<boolean> => {

    const user = await get<UserDetails>(`users/${username}`);
    return user.data.admin
};

// HOC that checks for admin status
const withAdminCheck = <P extends object>(WrappedComponent: ComponentType<P>, redirectPath: string = '/') => {

  return (props: P) => {
    const [isAdminStatus, setIsAdminStatus] = React.useState<boolean | null>(null);
    const navigate = useNavigate();
    const username = useSelector((state: {user: UserDetails}) => state.user.username)

    useEffect(() => {
        const checkAdminStatus = async () => {
          const adminStatus = await isAdmin(username as string);
          setIsAdminStatus(adminStatus);
  
          if (!adminStatus) {
            navigate(redirectPath);
          }
        };
  
        checkAdminStatus();
      }, [navigate, redirectPath]);

    return isAdminStatus  ? <WrappedComponent {...props} /> : null;
  };
};

export default withAdminCheck;
