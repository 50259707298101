import { RgbaColor } from "react-colorful";

const blue : RgbaColor = { r: 48, g: 54, b: 150, a: 1 };
const orange : RgbaColor = { r: 221, g: 161, b: 75, a: 1 };
const purple : RgbaColor = { r: 128, g: 0, b: 128, a: 1 };
const yellow : RgbaColor = { r: 255, g: 215, b: 0, a: 1 };
const red : RgbaColor= { r: 211, g: 39, b: 62, a: 1 };
const black : RgbaColor= { r: 0, g: 0, b: 0, a: 1 };

export {
  blue,
  orange,
  purple,
  yellow,
  red,
  black,
}
