import { useEffect, useState } from "react";
import { PaginationData, searchActions, defaultPaginationData } from "actions/search";

const ARRAY_SIZE = 20;
const RESPONSE_TIME_IN_MS = 1000;

interface Response {
  hasNextPage: boolean;
  paginationData: PaginationData,
  data: string[];
}

function isNil(value: any): boolean {
    return value === undefined || value === null;
}


const PAGE_SIZE: number = 25;

function loadItems(keyword: string, paginationData: PaginationData, type?: string, selectedKey?: string, category?: string): Promise<Response> {
  return new Promise((resolve) => {
    switch(type)
    {
      case('network'):
      {
        searchActions().searchNetwork(keyword, paginationData.currentPage + 1, PAGE_SIZE)
        .then((result) => {
            return resolve({ hasNextPage: result.pagination.currentPage < result.pagination.totalPages, paginationData: result.pagination, data: result.results });
        });
        break;
      }
      case('current-layer'):
      {
        if(category === 'Assets'){
          const assetKeyElements = selectedKey?.split('+') ?? [];
          const assedCode = assetKeyElements[assetKeyElements.length - 1];
          searchActions().searchAssets(keyword, assedCode, paginationData.currentPage + 1, PAGE_SIZE)
          .then((result) => {
              return resolve({ hasNextPage: result.pagination.currentPage < result.pagination.totalPages, paginationData: result.pagination, data: result.results });
          });
        }else if(category === 'Accidents'){
          const assetKeyElements = selectedKey?.split('+') ?? [];
          const year = assetKeyElements[assetKeyElements.length - 1];
          searchActions().searchAccidents(keyword, year, paginationData.currentPage + 1, PAGE_SIZE)
          .then((result) => {
              return resolve({ hasNextPage: result.pagination.currentPage < result.pagination.totalPages, paginationData: result.pagination, data: result.results });
          });
        }
        break;
      }
      default:
        break;
    }
  });
}

export function useLoadItems(type?: string, selectedKey?: string, keyword?: string, category?: string) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<string[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [paginationData, setPaginationData] = useState<PaginationData>(defaultPaginationData);

  useEffect(() => {
    if(!isNil(type) && !isNil(keyword)){
        setHasNextPage(true);
        setPaginationData({...paginationData, currentPage: 1});
        setItems([]);
    }

  }, [type, selectedKey, keyword, category])

  async function loadMore() {
    setLoading(true);

    try {
      const { data, paginationData: currentPaginationData , hasNextPage: newHasNextPage } = await loadItems( keyword ?? '', paginationData, type, selectedKey, category);
      setItems((current) => [...current, ...data]);
      setHasNextPage(newHasNextPage);
      setPaginationData(currentPaginationData);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { loading, items, paginationData, hasNextPage, error, loadMore };
}
