import React from 'react'
import Draggable from 'react-draggable';
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md';
import { UserInfo } from 'actions/administration-tools';
import Button from 'components/Button';

type CommentsLoaderProps = {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  currentUser: UserInfo | null,
  commentUser: (tenant: string, id: number, comment: string) => Promise<void>;
  deleteUserComments: (tenant: string, id: number) => Promise<void>;
};

const CommentsLoader: React.FC<CommentsLoaderProps> = ({ isOpen, setIsOpen, currentUser, commentUser, deleteUserComments }) => {
  const [inputContent, setInputContent] = React.useState<string>('');
  const [editEnabled, setEditEnabled] = React.useState<boolean>(false);
  const [confirmIsOpen,setConfirmIsOpen] = React.useState<boolean>(false);


  const onChangeSearchContent = (event: any) => {
    setInputContent(event.target.value);
  }

  const onEdit = () => {
    setEditEnabled(!editEnabled)
    if (currentUser?.comments && !editEnabled) {
      setInputContent(currentUser?.comments)
    }
    if(editEnabled){
      if(!currentUser?.tenant || !currentUser?.id) return;
      commentUser(currentUser?.tenant, currentUser?.id, inputContent)
    }
  }

  const onDelete = () => {
    if(!currentUser?.tenant || !currentUser?.id) return;
    if (currentUser?.comments && !editEnabled) {
      setInputContent(currentUser?.comments)
    }
    setInputContent(''); 
    setConfirmIsOpen(false);
    deleteUserComments(currentUser?.tenant, currentUser?.id)
  }

  const closeModal = () => {
    setEditEnabled(false);
    setInputContent('')
    setIsOpen(false);
  }
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-title"
        bounds="body"
      >
        <div className={`react-modal top-left`}>
          <div className="modal-header">
            <div className="modal-title">{currentUser?.firstname}'s comments</div>
            <div className="modal-header-buttons">
              <div onClick={() => closeModal()} className="modal-close" >
                <MdClose />
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div>
              <textarea
                placeholder={`${editEnabled ? 'Enter new comment:' : currentUser?.comments ? currentUser?.comments : 'User doesn\'t have comments!'}`} value={inputContent}
                onChange={onChangeSearchContent} disabled={!editEnabled} className={`${editEnabled ? 'edit-mode' : ''}`} rows={10} />
            </div>
            <div className="modal-footer">

              <div style={{ display: 'flex' }}>
                <Button onClick={() => setConfirmIsOpen(true)} color="danger" full isButton>
                  Delete
                </Button>
                <Button onClick={() => { onEdit() }} color={`${editEnabled ? 'action' : 'secondary'}`} full isButton>
                  {editEnabled ? 'Update' : 'Edit'}
                </Button>
                <Button onClick={() => closeModal()} color="primary" isButton>
                  Cancel
                </Button>
              </div>
              {
                confirmIsOpen ? 
                <div className="delete-confirmation-modal">
                  <p>
                    Are you sure you want to delete this comment?<br />This action is irreversible!
                  </p>
                  <div className="modal-footer" style={{display: 'flex'}}>
                    <Button onClick={() => onDelete()} color="danger" full isButton>
                      Delete
                    </Button>
                    <Button onClick={() => setConfirmIsOpen(false)} color="secondary" isButton>
                      Cancel
                    </Button>
                  </div>
                </div> : null
               }
            </div>
          </div>
        </div>
      </Draggable>
    </Modal>
  )
}
export default CommentsLoader;