export const getRefreshDataHeader = (refreshData: boolean) => {
  if (refreshData) {
    return (
      {
        headers: {
          RefreshData: true
        }
      }
    )
  }
}
