import { GridApi, ColumnApi, ApplyColumnStateParams } from 'ag-grid-community';
import Menu, { Item as MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';
import { IoIosArrowDown } from "react-icons/io";
import React from 'react';

export const saveGridState = (gridApi: GridApi, columnApi: ColumnApi): void => {
    const filterModel = gridApi?.getFilterModel();
    const columnState = columnApi?.getColumnState();

    localStorage.setItem('gridFilterModel', JSON.stringify(filterModel));
    localStorage.setItem('gridColumnState', JSON.stringify(columnState));
};

export const restoreGridState = (gridApi: GridApi, columnApi: ColumnApi): void => {
    const savedFilterModel = localStorage.getItem('gridFilterModel');
    const savedColumnState = localStorage.getItem('gridColumnState');

    if (savedFilterModel) {
        gridApi?.setFilterModel(JSON.parse(savedFilterModel));
    }
    if (savedColumnState) {
        const columnStateParams: ApplyColumnStateParams = {
            state: JSON.parse(savedColumnState),
            applyOrder: true
        };
        columnApi?.applyColumnState(columnStateParams);
    }
};

export const GRID_OPTIONS = ["Export CSV"]

export const RenderGridOptions = ({ onBtnExport }: any) => {
    const [selectedKeys, setSelectedKeys] = React.useState([]);

    const onSelect = ({ key }: any) => {
        if (key == 0) {
            return onBtnExport()
        }
        setSelectedKeys([]);
    }

    const menu = (
        <Menu onSelect={(key) => onSelect(key)} selectedKeys={selectedKeys}>
            {GRID_OPTIONS.map((item: string, index: number) => {
                return (
                    <>
                        <MenuItem key={index}>{item}</MenuItem>
                    </>
                )
            })}
        </Menu>
    );

    return (
        <Dropdown
            trigger={['click']}
            overlay={menu}
            animation="slide-up"
        >
            <div className='xa-grid-dropdown-btn'> <IoIosArrowDown size={14} />Grid options</div>
        </Dropdown>
    )
}